import { LngLatBoundsLike } from 'react-map-gl';

import { Coordinate } from '../@types';

interface MapBoundsParams {
  customerCoordinates: Coordinate;
  merchantCoordinates: Coordinate;
  vehicleCoordinates: Coordinate;
  route: Coordinate[];
}

export const calculateMapBounds = ({
  customerCoordinates,
  merchantCoordinates,
  vehicleCoordinates,
  route,
}: MapBoundsParams) => {
  let boundingCoords = [customerCoordinates, merchantCoordinates];

  if (route?.length > 0) {
    // route points comes from server in the form [lng, lat] and must be reversed
    const latLngRoute = route.map(([lng, lat]) => [lat, lng] as Coordinate);

    boundingCoords = [...boundingCoords, ...latLngRoute];
  }

  if (vehicleCoordinates?.length > 0) {
    boundingCoords = [...boundingCoords, vehicleCoordinates];
  }

  // https://stackoverflow.com/a/75402527
  const allLats: number[] = [];
  const allLngs: number[] = [];
  boundingCoords.forEach((cord) => {
    if (cord[0] !== 0 && cord[1] !== 0) {
      allLats.push(cord[0]);
      allLngs.push(cord[1]);
    }
  });
  const ne = [Math.max(...allLngs), Math.max(...allLats)];
  const sw = [Math.min(...allLngs), Math.min(...allLats)];

  return [sw, ne] as LngLatBoundsLike;
};
