import styled from '@emotion/styled';
import { FeatureCollection } from 'geojson';
import { useEffect, useMemo, useRef, useState } from 'react';
import Map, { Layer, MapRef, Source } from 'react-map-gl';

import { Colors } from '@coco/ui-web';

import { AttemptProvider, Coordinate, TripType } from '../../@types';
import { MAPBOX_ACCESS_TOKEN } from '../../lib/env';
import { calculateMapBounds } from '../../lib/map';
import { CarMarker, CustomerMarker, MerchantMarker, RobotMarker } from './Markers';

interface Props {
  customerCoordinates: Coordinate;
  merchantCoordinates: Coordinate;
  vehicleCoordinates: Coordinate;
  route: Coordinate[];
  currentTripType: TripType | undefined;
  providedBy: AttemptProvider;
}

// https://docs.mapbox.com/help/getting-started/attribution/
const MapContainer = styled.div({
  '& .mapboxgl-ctrl-bottom-left': {
    opacity: '0.5',
  },
  '& .mapboxgl-ctrl-bottom-right': {
    opacity: '0.5',
  },
});

export const LiveMap = ({
  customerCoordinates,
  merchantCoordinates,
  route,
  vehicleCoordinates,
  currentTripType,
  providedBy,
}: Props) => {
  const mapRef = useRef<MapRef>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const showBot = useMemo(
    () => vehicleCoordinates[0] !== 0 && vehicleCoordinates[1] !== 0 && providedBy === AttemptProvider.ROBOT,
    [providedBy, vehicleCoordinates]
  );

  const showDriver = useMemo(
    () =>
      vehicleCoordinates[0] !== 0 &&
      vehicleCoordinates[1] !== 0 &&
      [AttemptProvider.DOOR_DASH, AttemptProvider.UBER].includes(providedBy),
    [providedBy, vehicleCoordinates]
  );

  const geojson = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: route,
          },
          properties: {
            color: currentTripType === TripType.JITP ? Colors.blue500 : Colors.green500,
          },
        },
      ],
    } as FeatureCollection;
  }, [currentTripType, route]);

  useEffect(() => {
    if (mapLoaded) {
      const bounds = calculateMapBounds({
        customerCoordinates: currentTripType === TripType.JITP ? [0, 0] : customerCoordinates,
        merchantCoordinates,
        vehicleCoordinates,
        route,
      });
      mapRef.current?.fitBounds(bounds, {
        padding: {
          left: 30,
          top: 30,
          right: 30,
          bottom: 30,
        },
      });
    }
  }, [vehicleCoordinates, currentTripType, customerCoordinates, mapLoaded, merchantCoordinates, route]);

  return (
    <MapContainer>
      <Map
        ref={mapRef}
        initialViewState={{
          latitude: merchantCoordinates[0],
          longitude: merchantCoordinates[1],
          zoom: 14,
        }}
        style={{ height: '300px', marginBottom: '20px' }}
        mapStyle="mapbox://styles/mapbox/light-v11"
        onLoad={() => {
          setMapLoaded(true);
        }}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      >
        <CustomerMarker latitude={customerCoordinates[0]} longitude={customerCoordinates[1]} />

        <MerchantMarker latitude={merchantCoordinates[0]} longitude={merchantCoordinates[1]} shouldDim={showBot} />

        {showBot && <RobotMarker latitude={vehicleCoordinates[0]} longitude={vehicleCoordinates[1]} />}
        {showDriver && <CarMarker latitude={vehicleCoordinates[0]} longitude={vehicleCoordinates[1]} />}

        <Source type="geojson" data={geojson}>
          <Layer
            type="line"
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
            }}
            paint={{
              'line-color': ['get', 'color'],
              'line-width': 4,
            }}
          />
        </Source>
      </Map>
    </MapContainer>
  );
};
