import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import LocizeHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { Locale } from 'src/@types';
import { LOC_NS } from './types';

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  i18next
    .use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false, // this is a bit spammy; turn it on if there are any internationalization issues
      fallbackLng: Locale.EN,
      supportedLngs: Object.entries(Locale).map(([, value]) => value),
      interpolation: {
        escapeValue: false,
      },
      ns: Object.values(LOC_NS),
      backend: LocizeHttpBackend,
    });
}

export default i18next;
