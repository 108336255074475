import { AxiosInstance } from 'axios';
import { differenceInHours, startOfDay } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery, UseQueryOptions } from 'react-query';

import { AttemptStatus } from '@coco/types/deliveries';

import { useAppContext } from 'src/components/AppContext';
import { populateDeliveriesWithPII } from 'src/lib/deliveries';
import { AttemptProvider, DeliveryOperationalMode, ListDelivery } from '../@types';

const PAGE_LIMIT = 10;
const MAX_PAGES = 10;
const FIFTEEN_SECONDS_IN_MS = 15 * 1000;

interface QueryV2Response {
  data: {
    deliveries: ListDelivery[];
  };
  total: number; // tells us how many total deliveries there are, used for pagination
}

const getDeliveriesForPartner = async (
  deliveriesApi: AxiosInstance,
  privacyApi: AxiosInstance,
  partnerIds: string[],
  useMerchantDashboardNewFeatures: boolean,
  limit: number,
  offset: number
): Promise<QueryV2Response> => {
  const paramsWithNewFeatures = {
    // includes courier and scheduled orders
    partnerIds,
    includeLocation: true,
    operationalModes: [
      DeliveryOperationalMode.Scheduled,
      DeliveryOperationalMode.InProgress,
      DeliveryOperationalMode.RescueRequested,
      DeliveryOperationalMode.RescueInProgress,
      DeliveryOperationalMode.Canceled,
    ],
    isActivePartner: true,
    lastNHours: differenceInHours(new Date(), startOfDay(new Date())) + 1, // overshoot by one hour to cover entire day
    limit,
    offset,
    includeDeliveryMedium: true,
  };

  const paramsWithOldFeatures = {
    // does not have courier or scheduled orders
    partnerIds,
    includeLocation: true,
    statuses: [
      AttemptStatus.Requested,
      AttemptStatus.Pending,
      AttemptStatus.LoadedWaitingForPilot,
      AttemptStatus.LoadedWithPilot,
      AttemptStatus.InTransit,
      AttemptStatus.AtDestination,
      AttemptStatus.Scheduled,
      AttemptStatus.AtPickup,
      AttemptStatus.Assigned,
    ],
    providers: [AttemptProvider.ROBOT, AttemptProvider.UBER],
    isActivePartner: true,
    lastNHours: differenceInHours(new Date(), startOfDay(new Date())) + 1, // overshoot by one hour to cover entire day
    limit,
    offset,
    includeDeliveryMedium: true,
  };
  const { data: queryRes } = await deliveriesApi.get(`/api/v2/delivery/query-v2`, {
    params: useMerchantDashboardNewFeatures ? paramsWithNewFeatures : paramsWithOldFeatures,
  });

  const { data, ...restQueryData } = queryRes;
  const deliveriesWithPII = await populateDeliveriesWithPII(data.deliveries, privacyApi);
  return { ...restQueryData, data: { ...data, deliveries: deliveriesWithPII } };
};

/* 
  Since deliveries are pre-sorted by descending creation time, we can't not show deliveries =
  from early on in case they haven't been delivered yet
*/
const recursivelyFetchDeliveries = async (
  deliveriesApi: AxiosInstance,
  privacyApi: AxiosInstance,
  partnerIds: string[],
  useMerchantDashboardNewFeatures = false,
  page = 0,
  acc: ListDelivery[]
): Promise<ListDelivery[]> => {
  if (page > MAX_PAGES) {
    // recursion upper limit
    return acc;
  }
  const { data, total } = await getDeliveriesForPartner(
    deliveriesApi,
    privacyApi,
    partnerIds,
    useMerchantDashboardNewFeatures,
    PAGE_LIMIT,
    PAGE_LIMIT * page
  );
  const { deliveries } = data;

  if (deliveries.length && acc.length + deliveries.length >= total) {
    // no more data to fetch
    return [...acc, ...deliveries];
  }

  if (deliveries.length && acc.length < total) {
    // still more data to fetch
    return recursivelyFetchDeliveries(
      deliveriesApi,
      privacyApi,
      partnerIds,
      useMerchantDashboardNewFeatures,
      page + 1,
      [...acc, ...deliveries]
    );
  }

  return acc; // should not get here
};

interface UseDeliveriesParams {
  params: {
    partnerIds: string[];
  };
  config: UseQueryOptions;
}

const useDeliveries = ({ params, config }: UseDeliveriesParams) => {
  const { deliveriesApi, privacyApi } = useAppContext();
  const { useMerchantDashboardNewFeatures } = useFlags();

  return useQuery({
    queryKey: 'merchant-deliveries-active',
    queryFn: () =>
      recursivelyFetchDeliveries(deliveriesApi, privacyApi, params.partnerIds, useMerchantDashboardNewFeatures, 0, []),
    refetchIntervalInBackground: true,
    refetchInterval: FIFTEEN_SECONDS_IN_MS,
    ...config,
  });
};

export default useDeliveries;
