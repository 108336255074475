import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarLoader } from 'react-spinners';

import { AttemptStatus } from '@coco/types/deliveries';
import { CocoButtonV2 } from '@coco/ui-web';

import { CANCEL_DELIVERY_KEYS, LOC_NS } from 'src/i18n/types';
import { cancelableAttemptStatuses } from 'src/lib/deliveries';
import { trackSegment } from 'src/lib/segment';
import { useDeliveryStore } from 'src/stores/delivery';
import {
  AttemptProvider,
  BotInfo,
  Coordinate,
  DeliveryOperationalMode,
  DeliverySource,
  DoordashDriverInfo,
  EVENTS,
  ListDelivery,
  TrackerDeliveryInfo,
  Trip,
  TripType,
} from '../../@types';
import { delayAsync } from '../../lib/utils';
import { LiveMap } from '../Map/LiveMap';
import DeliveryReceipt from './DeliveryReceipt';
import ProgressBar from './ProgressBar';

interface Props {
  deliveryInfo: TrackerDeliveryInfo;
  isActiveOrdersTab: boolean;
  currentTrip: Trip | undefined;
  listDelivery: ListDelivery;
}

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomerDetailsContainer = styled.div`
  margin-right: 10px;
`;

const Copy = styled.div`
  margin-bottom: 10px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const DeliveryDetails = ({ deliveryInfo, listDelivery, isActiveOrdersTab, currentTrip }: Props) => {
  const { t } = useTranslation(LOC_NS.CANCEL_DELIVERY);
  const { user } = useAuth0();
  const [mapReady, setMapReady] = useState<boolean>(false);

  const { statusTimestampsMap, createdAt, providedBy } = listDelivery;

  const { setSelectedDelivery } = useDeliveryStore();

  useEffect(() => {
    // if map loads in too fast after switching from another order, it won't render
    const run = async () => {
      await delayAsync(1000);
      setMapReady(true);
    };

    run();
  }, []);

  const orderCanceled = useMemo(
    () =>
      listDelivery?.operationalMode === DeliveryOperationalMode.Canceled ||
      listDelivery?.currentAttemptStatus === AttemptStatus.Canceled,
    [listDelivery?.currentAttemptStatus, listDelivery?.operationalMode]
  );

  const handleOpenCancelModal = useCallback(
    (listDelivery: ListDelivery) => {
      // When the merchant clicks on Cancel Order button (open modal)
      trackSegment(EVENTS.BUTTON_CLICKED, {
        button_name: 'Cancel Order',
        order_id: listDelivery?.id,
        attempt_status: listDelivery?.currentAttemptStatus,
        auth_id: user?.sub,
        button_clicked_at: Date.now(),
      });

      setSelectedDelivery(listDelivery);
    },
    [setSelectedDelivery, user?.sub]
  );

  const orderisCancelable = useMemo(() => {
    if (
      isActiveOrdersTab &&
      listDelivery?.currentAttemptStatus &&
      listDelivery.orderSource !== DeliverySource.DOOR_DASH_MARKETPLACE &&
      cancelableAttemptStatuses.includes(listDelivery.currentAttemptStatus)
    ) {
      return true;
    }

    return false;
  }, [isActiveOrdersTab, listDelivery.currentAttemptStatus, listDelivery.orderSource]);

  const customerCoordinates = [
    deliveryInfo.customerInfo.coordinates[0],
    deliveryInfo.customerInfo.coordinates[1],
  ] as Coordinate;

  const merchantCoordinates = [
    deliveryInfo.merchantInfo.coordinates[0],
    deliveryInfo.merchantInfo.coordinates[1],
  ] as Coordinate;

  const { robotLat, robotLng } = useMemo(() => {
    if (
      deliveryInfo.providedBy === AttemptProvider.ROBOT &&
      (deliveryInfo?.deliveryMedium as BotInfo)?.coordinates?.latitude &&
      (deliveryInfo?.deliveryMedium as BotInfo)?.coordinates?.longitude
    ) {
      const robotDeliveryMedium = deliveryInfo.deliveryMedium as BotInfo;
      return {
        robotLat: robotDeliveryMedium?.coordinates?.latitude ?? 0,
        robotLng: robotDeliveryMedium?.coordinates?.longitude ?? 0,
      };
    }
    return {
      robotLat: 0,
      robotLng: 0,
    };
  }, [deliveryInfo.deliveryMedium, deliveryInfo.providedBy]);

  const { doordashDriverLat, doordashDriverLng } = useMemo(() => {
    if (
      deliveryInfo.providedBy === AttemptProvider.DOOR_DASH &&
      (deliveryInfo?.deliveryMedium as DoordashDriverInfo)?.coordinates?.latitude &&
      (deliveryInfo?.deliveryMedium as DoordashDriverInfo)?.coordinates?.longitude
    ) {
      const doordashDeliveryMedium = deliveryInfo.deliveryMedium as DoordashDriverInfo;
      return {
        doordashDriverLat: doordashDeliveryMedium?.coordinates?.latitude ?? 0,
        doordashDriverLng: doordashDeliveryMedium?.coordinates?.longitude ?? 0,
      };
    }

    return {
      doordashDriverLat: 0,
      doordashDriverLng: 0,
    };
  }, [deliveryInfo.deliveryMedium, deliveryInfo.providedBy]);

  let mainContent;
  if (
    (currentTrip?.type === TripType.DELIVERY &&
      !!listDelivery.currentAttemptStatus &&
      ([AttemptStatus.Pending, AttemptStatus.AtPickup] as AttemptStatus[]).includes(
        listDelivery.currentAttemptStatus
      )) ||
    !listDelivery.currentAttemptStatus
  ) {
    mainContent = <DeliveryReceipt deliveryId={listDelivery.id} />;
  } else if (listDelivery.providedBy === AttemptProvider.DOOR_DASH && orderisCancelable) {
    mainContent = <DeliveryReceipt deliveryId={listDelivery.id} />;
  } else if (orderisCancelable || orderCanceled) {
    mainContent = <DeliveryReceipt deliveryId={listDelivery.id} />;
  } else if (isActiveOrdersTab && mapReady && deliveryInfo.providedBy === AttemptProvider.ROBOT) {
    mainContent = (
      <div style={{ height: '300px', width: '100%' }}>
        <LiveMap
          vehicleCoordinates={[robotLat, robotLng]}
          customerCoordinates={customerCoordinates}
          merchantCoordinates={merchantCoordinates}
          route={currentTrip?.routeCoordinates ?? []}
          currentTripType={currentTrip?.type}
          providedBy={deliveryInfo.providedBy}
        />
      </div>
    );
  } else if (isActiveOrdersTab && mapReady && deliveryInfo.providedBy === AttemptProvider.DOOR_DASH) {
    mainContent = (
      <div style={{ height: '300px', width: '100%' }}>
        <LiveMap
          vehicleCoordinates={[doordashDriverLat, doordashDriverLng]}
          customerCoordinates={customerCoordinates}
          merchantCoordinates={merchantCoordinates}
          route={currentTrip?.routeCoordinates ?? []}
          currentTripType={currentTrip?.type}
          providedBy={deliveryInfo.providedBy}
        />
      </div>
    );
  } else if (isActiveOrdersTab) {
    mainContent = (
      <LoaderContainer>
        <BarLoader color="#141313" />
      </LoaderContainer>
    );
  }

  let progressBarContent;
  if (
    !isActiveOrdersTab &&
    createdAt &&
    (statusTimestampsMap?.LoadedWaitingForPilot || statusTimestampsMap?.InTransit) &&
    statusTimestampsMap?.AtDestination &&
    statusTimestampsMap?.Delivered
  ) {
    const timeLoaded = statusTimestampsMap.LoadedWaitingForPilot ?? statusTimestampsMap.InTransit ?? '';

    progressBarContent = (
      <ProgressBar
        timeRequested={createdAt}
        timeLoaded={timeLoaded}
        timeAtCustomer={statusTimestampsMap.AtDestination}
        timeDelivered={statusTimestampsMap.Delivered}
        isRobotOrder={providedBy === AttemptProvider.ROBOT}
      />
    );
  }

  return (
    <Container>
      {progressBarContent}
      <Row>
        <CustomerDetailsContainer>
          {!!deliveryInfo?.customerInfo?.address && <Copy>{deliveryInfo.customerInfo.address}</Copy>}
        </CustomerDetailsContainer>

        {orderisCancelable && !orderCanceled && (
          <CocoButtonV2
            size="large"
            buttonType="outlined"
            status={orderCanceled ? 'disabled' : 'enabled'}
            hideShadows
            onClick={() => handleOpenCancelModal(listDelivery)}
          >
            {t(CANCEL_DELIVERY_KEYS.CANCEL_DELIVERY, 'Cancel Delivery')}
          </CocoButtonV2>
        )}
      </Row>

      {mainContent}
    </Container>
  );
};

export default DeliveryDetails;
