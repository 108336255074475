import { addMilliseconds } from 'date-fns';
import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { toast } from 'react-toastify';

import { ListDelivery } from 'src/@types';
import { LateLoadedOrder } from '../Alert';

interface Props {
  experiment: {
    lateOrderDisplayDurationMs: number;
    lateOrderDisplayDurationMins: number;
    lateOrderSnoozeTimeMs: number;
    repeatAudioCount: number;
  };
  numAvailableBots: number;
  lateLoadedDeliveries: ListDelivery[];
}

const LateOrder = ({ experiment, numAvailableBots, lateLoadedDeliveries }: Props) => {
  const { restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toast(
        <LateLoadedOrder
          repeatAudioCount={experiment.repeatAudioCount}
          numAvailableBots={numAvailableBots}
          lateLoadedDeliveries={lateLoadedDeliveries}
        />,
        {
          toastId: 'late-loaded-order',
          autoClose: experiment.lateOrderDisplayDurationMins * 60 * 1000,
        }
      );
    },
  });

  useEffect(() => {
    toast.onChange(({ id, status }) => {
      if (status === 'removed' && id === 'late-loaded-order') {
        console.log('restarting late loaded orders timer', experiment.lateOrderSnoozeTimeMs);
        restart(addMilliseconds(new Date(), experiment.lateOrderSnoozeTimeMs));
      }
    });
  }, [experiment.lateOrderSnoozeTimeMs, restart]);

  useEffect(() => {
    toast.onChange(({ id, status }) => {
      if (status === 'removed' && id === 'canceled-order') {
        console.log('canceled orders toast was just removed, reopen late loaded orders toast');
        restart(addMilliseconds(new Date(), 1000));
      }
    });
  }, [experiment.lateOrderSnoozeTimeMs, restart]);

  return null;
};

export default LateOrder;
