import { FeatureCollection, Point } from 'geojson';

import { AttemptStatus, DeliveryCancelReason } from '@coco/types/deliveries';

import { Coordinate } from './map';

export enum DeliverySource {
  UBER_EATS = 'uber_eats',
  LUNCHBOX = 'lunchbox',
  CHOW_NOW = 'chow_now',
  RITUAL = 'ritual',
  REVEL = 'revel',
  GRUB_HUB = 'grub_hub',
  GRUBHUB = 'grubhub',
  DOOR_DASH = 'door_dash',
  DOORDASH = 'doordash',
  TOAST = 'toast',
  POSTMATES = 'postmates',
  ALLY = 'ally',
  OLO = 'olo',
  OTTER = 'otter',
  COCO_MOBILE_APP = 'coco_mobile_app',
  DOOR_DASH_MARKETPLACE = 'door_dash_marketplace',
  UBER_EATS_MARKETPLACE = 'uber_eats_marketplace',
}

export enum AttemptProvider {
  ROBOT = 'Robot',
  UBER = 'Uber',
  DOOR_DASH = 'DoorDash',
}

export interface Location {
  type: 'Pickup' | 'Dropoff';
  name: string;
  latitude: number;
  longitude: number;
}

export interface Delivery {
  id: string;
  orderSource: DeliverySource;
  providedBy: AttemptProvider;
  locations: Location[];
}

export interface LocationData {
  name: string;
  geolocation: {
    latitude: number;
    longitude: number;
  };
}

export interface DeliveryGeolocations {
  deliveryId: string;
  fieldOpLocation: LocationData | null;
  robotLocation: LocationData | null;
}

export enum DeliveryOperationalMode {
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  RescueRequested = 'RescueRequested',
  RescueInProgress = 'RescueInProgress',
  Completed = 'Completed',
  Canceled = 'Canceled',
}

export interface Address {
  city: string;
  state: string;
  streetLine1: string;
  streetLine2: string;
  zip: string;
}

export enum DeliveryLocationType {
  PICKUP = 'Pickup',
  DROPOFF = 'Dropoff',
}

export interface DeliveryLocation {
  address1: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  state: string;
  type: DeliveryLocationType;
  zip: string;
}

export interface TrackerDeliveryInfo {
  activeAttemptId: string;
  customerInfo: {
    id: string;
    coordinates: [number, number];
    address: string;
    phoneLast4: string;
  };
  merchantInfo: {
    id: string;
    name: string;
    coordinates: [number, number];
    address: string;
  };
  eta: string;
  targetDropoffTime: string;
  distance?: string;
  scheduledFor: string;
  providedBy: AttemptProvider;
  deliveryMedium?: DeliveryMediumInfo;
}

export interface BotInfo {
  provider: typeof AttemptProvider.ROBOT;
  name?: string;
  phone?: string;
  botSerial: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  pickupTime?: string;
  dropoffTime?: string;
}

export interface UberDriverInfo {
  provider: typeof AttemptProvider.UBER;
  name: string;
  phone?: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  pickupTime?: string;
  dropoffTime?: string;
  dropoffVerificationImageUrl?: string;
}

export interface DoordashDriverInfo {
  provider: typeof AttemptProvider.DOOR_DASH;
  name?: string;
  phone?: string;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  pickupTime?: string;
  dropoffTime?: string;
  dropoffVerificationImageUrl?: string;
}

export type DeliveryMediumInfo = BotInfo | UberDriverInfo | DoordashDriverInfo;

export interface ListDelivery {
  id: string;
  cancelReason: DeliveryCancelReason | null;
  orderSource: DeliverySource | null;
  providedBy: AttemptProvider | null;
  partnerName: string;
  partnerId: string | null;
  customerName: string; // redacted for doordash marketplace EU deliveries
  distance: string | null;
  createdAt: string;
  updatedAt: string;
  eta: string;
  targetDropoffTime: string;
  travelTime: number | null;
  currentAttemptStatus: AttemptStatus | null;
  expectedLoadTimeMin: number;
  statusTimestampsMap: Partial<{
    [key in AttemptStatus]: string;
  }> | null;
  scheduledFor: string | null;
  operationalMode: DeliveryOperationalMode;
  hubId: string;
  locations: Array<DeliveryLocation>;
  cocoRoutingErrorCode: number | null;
  routingSource: string | null;
  deliveryMedium?: DeliveryMediumInfo;
  customerId?: string;
}

export enum MerchantType {
  RESTAURANT = 'restaurant',
  GROCERY = 'grocery',
}

export interface PolygonField {
  type: 'Polygon';
  coordinates: Coordinate[][];
}

export enum AlcoholProvider {
  YES = 'yes',
  NO = 'no',
  ONLY = 'only',
}

export interface OpenTime {
  start: string;
  end: string;
}

export interface OperatingHoursException {
  day: string;
  hours: OpenTime[];
}

export interface OperatingHours {
  monday: OpenTime[];
  tuesday: OpenTime[];
  wednesday: OpenTime[];
  thursday: OpenTime[];
  friday: OpenTime[];
  saturday: OpenTime[];
  sunday: OpenTime[];
  exceptions: OperatingHoursException[];
}

export interface Merchant {
  id: string;
  created_at: string;
  updated_at: string;
  type: MerchantType;
  name: string;
  address: Address;
  location: Coordinate;
  online: boolean;
  service_region: PolygonField;
  hours: OperatingHours;
  alcohol_provider: AlcoholProvider;
  lunchbox_uuid?: string;
  jolt_id?: string;
  use_handoffs: boolean;
  use_hand_deliveries: boolean;
  prep_time?: number;
}

export enum OrderType {
  DELIVERY = 'DELIVERY',
  PICK_UP = 'PICK_UP',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  UNKNOWN = 'UNKNOWN',
}

export enum DeliveryMethod {
  ROBOT = 'robot',
  HUMAN = 'human',
}

export enum TransportMethod {
  ROBOT = 'robot',
  MIKE = 'mike',
  COURIER = 'courier',
  INTERNAL_COURIER = 'internal_courier',
}

export interface RobotRoute {
  robot_id: string;
  route: FeatureCollection<Point>;
}

export enum IssuesBucket {
  ALCOHOL = 'alcohol',
  BACKEND_ISSUE = 'backend_issue',
  BOT_FLIP = 'bot_flip',
  CAR_COLLISSION = 'car_collision',
  COURIER = 'courier',
  CUSTOMER_NON_RESPONSE = 'customer_non_response',
  CUSTOMER_REQUEST = 'customer_request',
  DEAD_ZONE = 'dead_zone',
  FIELD_OPS_AVAILABILITY = 'field_ops_availability',
  HARDWARE = 'hardware',
  JOLT_AVAILABILITY = 'jolt_availability',
  MERCHANT_LODE = 'merchant_lode',
  MERCHANT_REQUEST = 'merchant_request',
  MERCHANT_SUCCESS = 'merchant_success',
  MULTI_BOT_ORDER = 'multi_bot_order',
  OLO_AVAILABILITY = 'olo_availability',
  OTHER = 'other',
  PEDESTRIAN_HIT = 'pedestrian_hit',
  PILOT_AVAILABILITY = 'pilot_availability',
  PILOT_ISSUE = 'pilot_issue',
  PREVENTATIVE = 'preventative',
  RAIN = 'rain',
  ROBOT_AVAILABILITY = 'robot_availability',
  RUNNER = 'runnner',
  SOFTWARE = 'software',
  SPILL = 'spill',
  TERRAIN = 'terrain',
  UNCLEAR = 'unclear',
  VANDALISM = 'vandalism',
}

export enum OrderSource {
  CHOW_NOW = 'chow_now',
  DOOR_DASH = 'door_dash',
  RITUAL = 'ritual',
  SQUARE = 'square',
  TOAST = 'toast',
  UBER_EATS = 'uber_eats',
  LUNCHBOX = 'lunchbox',
  COCO_MOBILE_APP = 'coco_mobile_app',
  COCO_WEB_STOREFRONT = 'coco_web_storefront',
  OTHER = 'other',
}

export enum OrderState {
  UNASSIGNED = 'unassigned',
  GOING_TO_MERCHANT = 'going_to_merchant',
  AT_MERCHANT = 'at_merchant',
  GOING_TO_CUSTOMER = 'going_to_customer',
  AT_CUSTOMER = 'at_customer',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export enum StateHistory {
  UNASSIGNED = 'unassigned',
  CREATED_AT = 'created_at',
  GOING_TO_MERCHANT = 'going_to_merchant',
  AT_MERCHANT = 'at_merchant',
  GOING_TO_CUSTOMER = 'going_to_customer',
  AT_CUSTOMER = 'at_customer',
  COMPLETED_AT = 'completed_at',
  CANCELLED_AT = 'cancelled_at',
  CONFIRMED_AT = 'confirmed_at',
}

export interface EventV2 {
  id: string;
  type: string;
  version: string;
  type_cls: string;
  created_at: Date;
  properties: Record<string, string | number>;
}

export enum TransportMethodChangeReason {
  ROBOT_AVAILABILITY = 'robot_availability',
  PILOT_AVAILABILITY = 'pilot_availability',
  COURIER_FAILURE = 'courier_failure',
  TERRAIN = 'terrain',
  PEDESTRIAN_INTERFERENCE = 'pedestrian_interference',
  LONG_LODE = 'long_lode',
  BOT_FLIP = 'bot_flip',
  HARDWARE = 'hardware',
  SOFTWARE = 'software',
  CUSTOMER_REQUEST = 'customer_request',
  MERCHANT_REQUEST = 'merchant_request',
  CUSTOMER_NON_RESPONSE = 'customer_non_response',
  LONG_ETA = 'long_eta',
  ALCOHOL_VERIFICATION = 'alcohol_verification',
  RAIN = 'rain',
  PREVENTATIVE = 'preventative',
  SPILL = 'spill',
  CAR_ACCIDENT = 'car_accident',
  OTHER = 'other',
  NONE = 'none',
}

export enum Mode {
  ACTIVE = 'ACTIVE',
  HISTORY = 'HISTORY',
}

export enum StatusType {
  LATE_LOADED = 'LATE_LOADED',
  WAITING_FOR_LOAD = 'WAITING_FOR_LOAD',
  IN_TRANSIT = 'IN_TRANSIT',
  AT_CUSTOMER = 'AT_CUSTOMER',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
  SCHEDULED = 'SCHEDULED',
}

export interface Status {
  statusCopy: string;
  timeCopy: string;
  statusType: StatusType;
}
