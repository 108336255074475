import { format as formatDate } from 'date-fns';
import { enUS, fi, sv } from 'date-fns/locale';
import i18n from 'i18next';

import { CDN_URL } from '@coco/ui-web';

import { AttemptProvider, DeliverySource, ListDelivery, Locale } from '../@types';

const SOURCE_MAP = <Record<DeliverySource, string>>{
  [DeliverySource.ALLY]: 'Ally',
  [DeliverySource.CHOW_NOW]: 'Chow Now',
  [DeliverySource.DOORDASH]: 'DoorDash',
  [DeliverySource.DOOR_DASH]: 'DoorDash',
  [DeliverySource.GRUBHUB]: 'Grubhub',
  [DeliverySource.GRUB_HUB]: 'Grubhub',
  [DeliverySource.LUNCHBOX]: 'Lunchbox',
  [DeliverySource.OLO]: 'Olo',
  [DeliverySource.OTTER]: 'Otter',
  [DeliverySource.POSTMATES]: 'Postmates',
  [DeliverySource.REVEL]: 'Revel',
  [DeliverySource.RITUAL]: 'Ritual',
  [DeliverySource.TOAST]: 'Toast',
  [DeliverySource.UBER_EATS]: 'Uber Eats',
  [DeliverySource.UBER_EATS_MARKETPLACE]: 'Uber Eats Marketplace',
  [DeliverySource.COCO_MOBILE_APP]: 'Coco',
  [DeliverySource.DOOR_DASH_MARKETPLACE]: 'DoorDash Marketplace',
};

const DELIVERY_SOURCE_ASSETS = <Record<DeliverySource, string>>{
  [DeliverySource.ALLY]: `${CDN_URL}/images/ally.png`,
  [DeliverySource.CHOW_NOW]: `${CDN_URL}/images/chownow.png`,
  [DeliverySource.DOORDASH]: `${CDN_URL}/images/doordash-v2.png`,
  [DeliverySource.DOOR_DASH]: `${CDN_URL}/images/doordash-v2.png`,
  [DeliverySource.GRUBHUB]: `${CDN_URL}/images/grubhub.png`,
  [DeliverySource.GRUB_HUB]: `${CDN_URL}/images/grubhub.png`,
  [DeliverySource.OLO]: `${CDN_URL}/images/olo.png`,
  [DeliverySource.OTTER]: `${CDN_URL}/images/otter.png`,
  [DeliverySource.REVEL]: `${CDN_URL}/images/revel.png`,
  [DeliverySource.RITUAL]: `${CDN_URL}/images/ritual.png`,
  [DeliverySource.TOAST]: `${CDN_URL}/images/toast.png`,
  [DeliverySource.UBER_EATS]: `${CDN_URL}/images/ubereats.png`,
  [DeliverySource.COCO_MOBILE_APP]: `${CDN_URL}/images/coco.png`,
  [DeliverySource.DOOR_DASH_MARKETPLACE]: `${CDN_URL}/images/doordash-v2.png`,
  [DeliverySource.UBER_EATS_MARKETPLACE]: `${CDN_URL}/images/ubereats.png`,
};

export const getOrderSourceNameFromSource = (orderSource: DeliverySource | null) => {
  if (!orderSource) {
    return '';
  }
  switch (orderSource) {
    case DeliverySource.DOOR_DASH:
    case DeliverySource.DOOR_DASH_MARKETPLACE:
      return 'DD';
    case DeliverySource.GRUBHUB:
    case DeliverySource.GRUB_HUB:
      return 'GH';
    case DeliverySource.UBER_EATS_MARKETPLACE:
    case DeliverySource.UBER_EATS:
      return 'UE';
    case DeliverySource.LUNCHBOX:
      return 'LB';
    case DeliverySource.CHOW_NOW:
      return 'CN';
    case DeliverySource.POSTMATES:
      return 'PM';
    default:
      return SOURCE_MAP[orderSource];
  }
};

export const getOrderSourceImageUrl = (orderSource: DeliverySource | null) => {
  if (orderSource && DELIVERY_SOURCE_ASSETS[orderSource]) {
    return DELIVERY_SOURCE_ASSETS[orderSource];
  }
  return '';
};

export const getProviderImageUrl = (provider: AttemptProvider) => {
  if (provider === AttemptProvider.ROBOT) {
    return `${CDN_URL}/images/icons/coco-pink-v2.png`;
  }

  if (provider === AttemptProvider.UBER) {
    return `${CDN_URL}/images/icons/fo-pink.png`;
  }

  if (provider === AttemptProvider.DOOR_DASH) {
    return `${CDN_URL}/images/icons/courier-black-v2.png`;
  }
};

/*
  await delayAsync(1000) would pause execution by 1 second
*/
export const delayAsync = async (delayMs: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), delayMs);
  });
};

export const coalescePages = (pages: Array<ListDelivery[]> | undefined): ListDelivery[] => {
  if (!pages) {
    return [];
  }

  let acc: ListDelivery[] = [];

  pages.forEach((page: ListDelivery[]) => {
    acc = [...acc, ...page];
  });

  // Remove duplicates in the array which can occur during hot reloading
  const filteredAcc = acc.filter((delivery, idx, curArray) => {
    return curArray.findIndex((ele) => ele.id === delivery.id) === idx;
  });

  return filteredAcc;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasBotSerial = (x: Record<string, any>): x is { botSerial: string } => {
  return x.botSerial !== undefined;
};

const LocaleMap = {
  [Locale.EN]: enUS,
  [Locale.FI]: fi,
  [Locale.SV]: sv,
};

export const getDateFnsLocale = () => {
  return LocaleMap[i18n.language as Locale] ?? enUS;
};

export const localeFormatDate = (date: Date | number, format: string) => {
  const locale = getDateFnsLocale();

  return formatDate(new Date(date), format, { locale });
};
