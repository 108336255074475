import { AxiosInstance } from 'axios';

import { PiiRecord } from '@coco/types/integrations';

export const getPII = async (privacyApi: AxiosInstance, customerId: string): Promise<PiiRecord | null> => {
  try {
    const { data } = await privacyApi.get('/privacy/v1/pii', {
      params: {
        customer_id: customerId,
        include_name: true,
        include_address: true,
      },
    });

    return data;
  } catch (err) {
    // until we figure out a better way to determine when to fetch PII, logs would be too noisy here
    return null;
  }
};
