import { AxiosInstance } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { ParkingLotRobot } from '../@types';
import { AppContext } from '../components';

interface Params {
  partnerId: string;
  config?: UseQueryOptions;
}

const fetchRobots = async (operationsApi: AxiosInstance, partnerId: string): Promise<ParkingLotRobot[]> => {
  const { data } = await operationsApi.get(`/robots`, { params: { partnerId } });
  return data;
};

const useRobots = ({ config, partnerId }: Params) => {
  const { operationsApi } = AppContext.useAppContext();
  return useQuery({
    queryKey: ['robots', partnerId],
    queryFn: () => fetchRobots(operationsApi, partnerId),
    ...config,
  });
};

export default useRobots;
