import { AxiosInstance } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getTrackerInfoWithPII } from 'src/lib/deliveries';
import { ListDelivery, TrackerDeliveryInfo } from '../@types';
import { AppContext } from '../components';

interface Params {
  deliveryId: ListDelivery['id'];
  config?: UseQueryOptions;
}

const fetchDeliveryInfo = async (
  deliveriesApi: AxiosInstance,
  privacyApi: AxiosInstance,
  deliveryId: string
): Promise<TrackerDeliveryInfo> => {
  const { data } = await deliveriesApi.get(`/api/v2/delivery/${deliveryId}/tracker-info`);

  return await getTrackerInfoWithPII(data, privacyApi);
};

const useDeliveryInfo = ({ deliveryId, config }: Params) => {
  const { deliveriesApi, privacyApi } = AppContext.useAppContext();

  return useQuery({
    queryKey: ['delivery-info', deliveryId],
    queryFn: () => fetchDeliveryInfo(deliveriesApi, privacyApi, deliveryId),
    ...config,
  });
};

export default useDeliveryInfo;
