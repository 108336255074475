import styled from '@emotion/styled';
import { FullStory } from '@fullstory/browser';
import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Colors } from '@coco/ui-web';

import { FS_EVENT, Locale } from 'src/@types';
import { COMMON_KEYS, LOC_NS } from 'src/i18n/types';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const ContentContainer = styled.div({
  width: '400px',
  '@media (max-width: 640px)': {
    width: '60vw',
  },
  padding: '24px 36px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Colors.grey100,
});

const Title = styled.div({
  fontSize: '18px',
  fontWeight: 600,
  paddingBottom: '30px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
});

export const SettingsModal = ({ open, handleClose }: Props) => {
  const { i18n, t } = useTranslation(LOC_NS.COMMON);

  const onChangeLanguage = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newLanguage = e.target.value;
      i18n.changeLanguage(newLanguage);
      FullStory('trackEvent', {
        name: FS_EVENT.LANGUAGE_CHANGE,
        properties: {
          newLanguage,
        },
      });
    },
    [i18n]
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <ContentContainer>
        <Title>{t(COMMON_KEYS.SETTINGS, 'Settings')}</Title>{' '}
        <FormControl>
          <InputLabel id="language-select-label">{t(COMMON_KEYS.LANGUAGE, 'Language')}</InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={i18n.resolvedLanguage ?? Locale.EN}
            label="Language"
            onChange={onChangeLanguage}
            sx={{ borderRadius: '10px', width: '100%' }}
          >
            <MenuItem value={Locale.EN}>English</MenuItem>
            <MenuItem value={Locale.FI}>Finnish</MenuItem>
            <MenuItem value={Locale.SV}>Swedish</MenuItem>
          </Select>
        </FormControl>
      </ContentContainer>
    </Dialog>
  );
};
