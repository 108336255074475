export enum LOC_NS {
  COMMON = 'Common',
  ORDERS = 'Orders',
  CANCEL_DELIVERY = 'CancelDelivery',
  DELIVERY_STATUS = 'DeliveryStatus',
  WARNINGS = 'Warnings',
}

export enum COMMON_KEYS {
  SETTINGS = 'SETTINGS',
  LANGUAGE = 'LANGUAGE',
  LOGOUT = 'LOGOUT',
  UNAUTHORIZED = 'UNAUTHORIZED',
  MISSING_PERMISSIONS = 'MISSING_PERMISSIONS',
  NEED_HELP = 'NEED_HELP',
  COCO_MERCHANT_SUPPORT = 'COCO_MERCHANT_SUPPORT',
  PLEASE_CALL_SUPPORT = 'Please call {{number}}',
  LOAD_MORE = 'LOAD_MORE',
  TAP_ANYWHERE_TO_CLOSE = 'TAP_ANYWHERE_TO_CLOSE',
  MIN = 'MIN',
  HRS = 'HRS',
  AGO = 'AGO',
  LESS_THAN_A_MIN = 'LESS_THAN_A_MIN',
}

export enum ORDERS_KEYS {
  ACTIVE_ORDERS = 'ACTIVE_ORDERS',
  ORDER_HISTORY = 'ORDER_HISTORY',
  REFRESH_ORDERS = 'REFRESH_ORDERS',
  NO_ACTIVE_ORDERS = 'NO_ACTIVE_ORDERS',
  UNABLE_TO_FETCH_ORDERS = 'UNABLE_TO_FETCH_ORDERS',
  UNABLE_TO_FETCH_ORDER_DETAILS = 'UNABLE_TO_FETCH_ORDER_DETAILS',
  CUSTOMER = 'CUSTOMER',
  MERCHANT = 'MERCHANT',
  ROBOT_WAITING_FOR = 'ROBOT_WAITING_FOR',
  ORDER = 'ORDER',
  ORDERS = 'ORDERS',
  RECEIVED_CANCELATION_FOR = 'RECEIVED_CANCELATION_FOR',
  CANCEL_DELIVERY = 'CANCEL_DELIVERY',
  TAX = 'TAX',
  TIP = 'TIP',
  SUBTOTAL = 'SUBTOTAL',
  TOTAL = 'TOTAL',
  ITEM = 'ITEM',
  ARRIVING_NOW = 'ARRIVING_NOW',
  ARRIVING_SOON = 'ARRIVING_SOON',
  ARRIVES_IN = 'ARRIVES_IN',
}

export enum CANCEL_DELIVERY_KEYS {
  CANCEL_DELIVERY = 'CANCEL_DELIVERY',
  CANCELS_DELIVERY_ONLY = 'CANCELS_DELIVERY_ONLY',
  CANCEL_DELIVERY_FOR = 'CANCEL_DELIVERY_FOR',
  REASON_FOR_CANCELING = 'REASON_FOR_CANCELING',
  CUSTOMER_CANCELED = 'CUSTOMER_CANCELED',
  CUSTOMER_PICKED_UP = 'CUSTOMER_PICKED_UP',
  DUPLICATE_ORDER = 'DUPLICATE_ORDER',
  KITCHEN_CLOSED = 'KITCHEN_CLOSED',
  RESTAURANT_TOO_BUSY = 'RESTAURANT_TOO_BUSY',
  UNAVAILABLE_ITEM = 'UNAVAILABLE_ITEM',
  OTHER = 'OTHER',
}

export enum DELIVERY_STATUS_KEYS {
  RECEIVED = 'RECEIVED',
  LOADED = 'LOADED',
  PICKED_UP = 'PICKED_UP',
  WAITING_FOR_DRIVER = 'WAITING_FOR_DRIVER',
  WAITING_FOR_LOAD = 'WAITING_FOR_LOAD',
  READY_FOR_LOAD = 'READY_FOR_LOAD',
  WAITING_FOR_PILOT = 'WAITING_FOR_PILOT',
  ON_THE_WAY_TO_PICKUP = 'ON_THE_WAY_TO_PICKUP',
  SUPPORT_HEADING_TO_BOT = 'SUPPORT_HEADING_TO_BOT',
  SUPPORT_REQUESTED = 'SUPPORT_REQUESTED',
  DRIVER_WAITING = 'DRIVER_WAITING',
  ON_THE_WAY_TO_CUSTOMER = 'ON_THE_WAY_TO_CUSTOMER',
  AT_CUSTOMER = 'AT_CUSTOMER',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
  SCHEDULED_FOR = 'SCHEDULED_FOR',
  ARRIVING_SOON = 'ARRIVING_SOON',
  TO_PICKUP = 'TO_PICKUP',
  TO_DEST = 'TO_DEST',
  ETA = 'ETA',
  ONE_MIN_TO_PICKUP = 'ONE_MIN_TO_PICKUP',
  LOAD_TIME_EXCEEDED = 'LOAD_TIME_EXCEEDED',
}

export enum WARNINGS_KEYS {
  LOW_BATTERY = 'LOW_BATTERY',
}
