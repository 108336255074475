import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import Image from 'next/image';
import { Marker } from 'react-map-gl';

import { Colors } from '@coco/ui-web';

import { CourierIconComplete, DestinationIconComplete, MerchantIconComplete, RobotIconComplete } from '../../assets';

const ASSET_SIZE_PX = 30;

interface Props {
  latitude: number;
  longitude: number;
  shouldDim?: boolean;
}

interface MerchantMarkerProps extends Props {
  shouldDim: boolean;
}

interface ImageContainerProps {
  shouldDim?: boolean;
}
const ImageContainer = styled.div(({ shouldDim = false }: ImageContainerProps) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: shouldDim ? 0.8 : 1,
}));

const pulse = keyframes({
  '0%': {
    borderColor: 'rgba(213, 110, 225, 0.19)',
  },
  '50%': {
    borderColor: 'rgba(224,104,180,0.6)',
  },
  '100%': {
    borderColor: 'rgba(213, 110, 225, 0.19)',
  },
});

const RobotImageContainer = styled(ImageContainer)({
  borderWidth: '9px',
  borderStyle: 'solid',
  borderRadius: '50%',
  borderColor: Colors.white,
  animation: `${pulse} 2s infinite`,
  width: `${ASSET_SIZE_PX}px`,
  height: `${ASSET_SIZE_PX}px`,
});

export const CustomerMarker = ({ latitude, longitude, shouldDim }: Props) => {
  return (
    <Marker latitude={latitude} longitude={longitude} style={{ zIndex: '1' }}>
      <ImageContainer shouldDim={shouldDim}>
        <Image alt="Home" src={DestinationIconComplete} width={ASSET_SIZE_PX} height={ASSET_SIZE_PX} />
      </ImageContainer>
    </Marker>
  );
};

export const MerchantMarker = ({ latitude, longitude, shouldDim }: MerchantMarkerProps) => {
  return (
    <Marker latitude={latitude} longitude={longitude} style={{ zIndex: '1' }}>
      <ImageContainer shouldDim={shouldDim}>
        <Image alt="Merchant" src={MerchantIconComplete} width={ASSET_SIZE_PX} height={ASSET_SIZE_PX} />
      </ImageContainer>
    </Marker>
  );
};

export const RobotMarker = ({ latitude, longitude }: Props) => {
  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      style={{ zIndex: '2' }} // ensure vehicle icon always appears on top of other icons
    >
      <RobotImageContainer>
        <Image alt="Robot" src={RobotIconComplete} width={ASSET_SIZE_PX} height={ASSET_SIZE_PX} />
      </RobotImageContainer>
    </Marker>
  );
};

export const CarMarker = ({ latitude, longitude }: Props) => {
  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      style={{ zIndex: '2' }} // ensure vehicle icon always appears on top of other icons
    >
      <RobotImageContainer>
        <Image alt="Car" src={CourierIconComplete} width={ASSET_SIZE_PX} height={ASSET_SIZE_PX} />
      </RobotImageContainer>
    </Marker>
  );
};
