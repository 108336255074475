import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CDN_URL, Colors, Typography } from '@coco/ui-web';

import { EVENTS, ListDelivery } from 'src/@types';
import { COMMON_KEYS, LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { trackSegment } from 'src/lib/segment';
import { useOrderStore } from 'src/stores/orders';

interface Props {
  closeToast?: () => void; // injected by react-toastify
  repeatAudioCount: number;
  numAvailableBots: number;
  lateLoadedDeliveries: ListDelivery[];
}

const Container = styled.div({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(250, 16, 16, 0.7)',
  cursor: 'pointer',
  padding: '24px',
});

const Body = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Title = styled.div({
  color: Colors.white,
  marginBottom: '20px',
  textAlign: 'center',
});

const CircleContainer = styled.div({
  width: '360px',
  height: '360px',
  borderRadius: '50%',
  backgroundColor: Colors.white,
  marginBottom: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-height: 675px)': {
    width: '240px',
    height: '240px',
  },
  '@media (max-width: 540px)': {
    width: '300px',
    height: '300px',
  },
});

const Count = styled.div({
  color: Colors.grey900,
  marginBottom: '24px',
});

const OrderCopy = styled.div({
  color: Colors.grey900,
});

const Caption = styled.div({
  color: Colors.grey900,
  marginBottom: '40px',
  textAlign: 'center',
});

const TapAnywhere = styled.div({
  color: Colors.white,
});

export const LateLoadedOrder = ({
  closeToast,
  repeatAudioCount = 1,
  numAvailableBots,
  lateLoadedDeliveries,
}: Props) => {
  const { t: commonT } = useTranslation(LOC_NS.COMMON);
  const { t: ordersT } = useTranslation(LOC_NS.ORDERS);
  const [audio] = useState(
    typeof Audio !== 'undefined' ? new Audio(`${CDN_URL}/audio/load-warning.mp3`) : ({} as typeof Audio)
  );

  const { user } = useAuth0();

  const { canceledOrders } = useOrderStore();

  const orderIds = useMemo(() => lateLoadedDeliveries.map(({ id }) => id), [lateLoadedDeliveries]);

  const count = lateLoadedDeliveries.length;

  const uniqueNames = useMemo(() => {
    const set = new Set<string>();
    lateLoadedDeliveries.forEach(({ customerName }) => {
      set.add(customerName);
    });

    const arr = Array.from(set);

    const trimmedUniqueNames = arr.map((name) => {
      return name.length > 15 ? `${name.slice(0, 15)}...` : name;
    });

    return trimmedUniqueNames as string[];
  }, [lateLoadedDeliveries]);

  const handleCloseToast = useCallback(() => {
    if (closeToast) {
      // When the merchant dismisses the reminder pop-up
      trackSegment(EVENTS.BUTTON_CLICKED, {
        order_count: count,
        button_name: 'Manual Dismiss - Long Load Alert Screen',
        order_ids: orderIds,
        auth_id: user?.sub,
        button_clicked_at: Date.now(),
      });
      audio?.pause();
      closeToast();
    }
  }, [audio, closeToast, count, orderIds, user?.sub]);

  useEffect(() => {
    let count = repeatAudioCount;
    let timer: NodeJS.Timeout;
    const run = () => {
      if (count > 0) {
        audio?.play();
        count -= 1;
        timer = setTimeout(run, 12000); // wait 4 seconds before next audio clip plays (it plays for 8 seconds)
      }
    };

    run();

    return () => clearTimeout(timer);
  }, [audio, repeatAudioCount]);

  useEffect(() => {
    // When the merchant sees the load reminder popup
    trackSegment(EVENTS.PAGE_VIEWED, {
      order_count: count,
      order_ids: orderIds,
      page_name: 'Long Load Alert Screen',
      auth_id: user?.sub,
      page_viewed_at: Date.now(),
    });
  }, [count, orderIds, user?.sub]);

  useEffect(() => {
    // handle case where alert no longer needs to be shown if there are no more late loaded orders
    if (lateLoadedDeliveries.length === 0 && closeToast) {
      console.log('toast -- dont show late since there are no more late loaded orders');
      closeToast();
    }
  }, [closeToast, count, lateLoadedDeliveries.length]);

  useEffect(() => {
    // handle case where alert should not be shown when there aren't any available bots
    if (numAvailableBots === 0 && closeToast) {
      console.log('toast -- dont show late since there are no available bots');
      closeToast();
    }
  }, [closeToast, numAvailableBots]);

  useEffect(() => {
    if (canceledOrders.length > 0 && closeToast) {
      console.log('toast - dismissing late alert since canceled orders take higher priority');
      closeToast();
    }
  }, [canceledOrders.length, closeToast]);

  return (
    <Container onClick={handleCloseToast}>
      <Body>
        <Title className={Typography.HEADLINE_LG}>
          {ordersT(ORDERS_KEYS.ROBOT_WAITING_FOR, 'Coco Robot waiting for')}
        </Title>

        <CircleContainer>
          <Count className={Typography.DISPLAY_LG}>{count}</Count>
          <OrderCopy className={Typography.DISPLAY_MD}>
            {count === 1 ? ordersT(ORDERS_KEYS.ORDER, 'Order') : ordersT(ORDERS_KEYS.ORDERS, 'Orders')}
          </OrderCopy>
        </CircleContainer>

        <Caption className={Typography.HEADLINE_MD}>{uniqueNames.length < 5 ? uniqueNames.join(' | ') : ''}</Caption>

        <TapAnywhere className={Typography.HEADLINE_SM}>
          {commonT(COMMON_KEYS.TAP_ANYWHERE_TO_CLOSE, 'Tap anywhere to close')}
        </TapAnywhere>
      </Body>
    </Container>
  );
};
